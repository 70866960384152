import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check out these mapps!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src='images/tablet-map.jpg'
              text='Discover the best hidden food in Seattle.'
              label='Best Hidden Food in Seattle'
              path='/mapps'
            />
            <CardItem
              src='images/map2.jpg'
              text="My covetted trout spots around the Hudson Bay"
              label='Trout fishing: Hudson Bay'
              path='/mapps'
            />
            <CardItem
              src='images/map3.jpg'
              text="Safe havens for those in need"
              label='Safe Havens'
              path='/mapps'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
