import './App.css';
import NavBar from './components/NavBar'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/pages/Home'
import Mapps from './components/pages/Mapps'

function App() {
  return (
    <>
    <Router>
      <NavBar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/mapps' component={Mapps}/>
      </Switch>
    </Router>
    </>
  );
}

export default App;
