import React from 'react'
import { Button } from './Button'
import './HeroSection.css'
import '../App.css'

function openVideo() {
    const videoUrl = 'https://youtu.be/dQw4w9WgXcQ?si=jEsWTt-ABTvrNdwk';
    window.open(videoUrl, '_blank'); // Opens the video URL in a new tab or window
  }

function HeroSection() {
    return (
            <div className='hero-container'>
                {/* <img src="../images/map3.jpg" /> */}
                <h1>MAPPS</h1>
                <p>Collect your adventures</p>
                <div className='hero-btns'>
                    <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                        Log in
                        </Button>
                    <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' onClick={openVideo}>
                        Sign up 
                        <i className='fas fa-user-plus'/>
                    </Button>
                </div>
            </div>
    )
}

export default HeroSection
